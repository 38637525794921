<template>
  <div id="Buy" class="buybox supernettFont"> 
    <div class="buybox_right">
      <h2 class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">How to Buy $ BSCM</h2>
      <p class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".2s">CONTRACT ADDRESS: 0x96ed4db01218608e6c8a8d66c65bdf59601ee735</p>
      <div class="bug_img">
        <div class="bug_img_rel">
          <img class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".22s" src="@/assets/image/bscm/buy.webp" v-if="!$utils.isMobile()" alt="">
          <img class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".22s" src="@/assets/image/bscm/buy_m.webp" v-else alt="">
          <div>
            <p>
              <button 
              class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".23s"
              @click="windowOpen('https://www.dextools.io/app/en/bnb/pair-explorer/0x9f0e0eb8886d7279b33a81c40e70a12f69861d5b?t=1714291509170', '_dextools')">
                dextool
              </button>
            </p>
            <p>
              <button 
              class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".24s"
              @click="windowOpen('https://dexscreener.com/bsc/0x9f0e0eb8886d7279b33a81c40e70a12f69861d5b', '_dexscrneer')">
                dexscrneer
              </button>
            </p>
            <p>
              <button 
              class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".25s"
              @click="windowOpen('https://ave.ai/token/0x96ed4db01218608e6c8a8d66c65bdf59601ee735-bsc?from=Default', '_ave')">
                ave
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
  },
  mounted(){
    var options={
        //默认为true
        live:false
    }
    new this.$WOW.WOW(options).init();
  },
  methods:{
    windowOpen(url,blank){
      window.open(url, blank);
    }
  }
}
</script>

<style lang="scss" scoped>
.buybox{
  display: flex;
  justify-content: center;
  margin-top: 80px;
  position: relative;
  padding-bottom: 100px;
}
.buybox::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 8px;
  bottom: 0px;
  background: url('../../assets/image/line.png') no-repeat center center/100%;
}
.buybox_right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2{
    color: #F8C10A;
    font-size: 36px; 
    text-transform: uppercase;
    margin: 14px 0px 32px;
  }
  >p{
    color: rgba(255,255,255,.5);
    font-size: 32px;
    margin-bottom: 88px;
  }
}
.bug_img{
  height: 942px;
  .bug_img_rel{
    position: relative;
    img{
      width: 1123px;
    }
    >div{
      position: absolute;
      bottom: -150px;
      width: 588px;
      display: flex;
      flex-direction: column;
      p{
        flex: 1;
        display: flex;
      }

      button{
        width: 306px;
        height: 78px;
        background: #F8BA00;
        border-radius: 6px;
        color: #000000;
        margin-bottom: 30px;
        font-size: 30px;
        color: #000000;
        line-height: 40px;
        text-transform: uppercase;
      }
      p:nth-child(2){
        justify-content: center;
      }
      p:nth-child(3){
        justify-content: flex-end;
      }
    }
  }
  
 
}
@media screen and (max-width:768px) {
  .buybox{
    width: 100%;
    margin-top: 80px;
    padding-bottom: 80px;
    &::before{
      display: none;
    }
  }
  .buybox_right{
    h2{
      font-size: 30px;
      margin: 0px 0px 14px;
    }
  >p{
    font-size: 13px;
    margin-bottom: 30px;
  }
}
.bug_img{
  height: auto;
  .bug_img_rel{
    position: relative;
    img{
      width: 100%;
      max-width: 359px;
    }
    >div{
      position:static;
      bottom: auto;
      width: 100%;
      margin-top: 28px;
      button{
        width: 210px;
        height: 40px;
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 40px;
      }
    }
  }
  
 
}
}
</style>